<template>
  <div class="excellentCourses">
    <div
      v-for="item in courseList"
      :key="item.index"
      class="course_item"
      @click="handleToLink(item.url)"
    >
      <div
        class="courseImg"
        :style="{ backgroundImage: `url(${item.img})` }"
      ></div>
      <!-- <p class="courseTitle">{{ item.title }}</p> -->
    </div>
  </div>
</template>
<script>
import { excellentCourseList } from "./data";
export default {
  data() {
    return {
      courseList: excellentCourseList,
    };
  },
  methods: {
    handleToLink(url) {
      window.open(url);
    },
  },
};
</script>
<style lang="scss" scoped>
$vw_base: 390;
// $vh_base: 1080;
@function vw($px) {
  //这个是移动端适配用到的
  @return ($px / $vw_base) * 100vw;
}
.excellentCourses {
  display: flex;
  // flex-direction: column;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  padding: vw(30);
  .course_item {
    width: vw(150);
    // background-color: pink;
    margin-top: vw(20);
    .courseImg {
      height: 90px;
      border: 1px solid rgba(95, 99, 104, 0.5);
      border-radius: 5px;
      background-size: 100% 100%;
      background-repeat: no-repeat;
    }
    .courseTitle {
      height: 60px;
      padding: 0 20px;
      line-height: 60px;
      font-size: vw(16);
      font-weight: 400;
      background-color: #fff;
    }
  }
  // .course_item + .course_item {
  //   margin-top: vw(20);
  // }
}
</style>
